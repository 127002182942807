html {
  min-height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: 100%;
  background-position: bottom;
  min-height: 100%;
  background-color: #fff!important;
}
input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]){
  border-color: blue;
  box-shadow: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.page-footer {
  background-color: #333;
  padding-top: 0px;
}
nav .brand-logo img {
  height: 64px;
}
.activeRoute {
  background-color: #000;
}
.MuiTableCell-head{
  font-weight: bold!important;
}
tfoot tr td,
tfoot tr,
tfoot .MuiTableCell-root{
  border: 0px;
}
.tableSection .MuiToolbar-regular{
  height: 10px;
  min-height: auto;
  
}
.tableSection tfoot .MuiToolbar-regular{
  height: 60px;
}
.container.body{
  margin-top: 80px;
}
.tableSection .MuiTableCell-root{
  padding: 5px 10px!important;
}
.tableSection .MuiTableSortLabel-root:hover,
.tableSection .MuiTableSortLabel-root.MuiTableSortLabel-active,
.tableSection .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon
{
  color: #fff!important;
}
.tableSection .MuiTableCell-head{
  background-color: #365698!important;
  color: #fff!important;
  font-weight: bold!important;
}
.tableSection td,
.tableSection th {
  border: 1px solid #c8c8c8;
}

.tableSection td.center,
.tableSection th.center {
  width: 40px;
  text-align: center; 
}
.input-field>label{
  color: #333;
}
div .container{
    flex-grow: 1;
    padding: 10px;
}
div.root-container{
  display: flex;
}
h2.title{
  margin-top: 0px;
  margin-bottom: 0px;
}
div.red-text{
  color: red;
}
.spinner{
  color: #fff;
}
.MuiListItemIcon-root{
  color: #333;
}
.appbar-right{
  flex: 1;
  margin-left: 10px;
  text-align: right;
}
.appbar-right .MuiTab-textColorSecondary.Mui-selected,
.appbar-right .MuiTab-textColorSecondary{
  color: #fff;
  text-transform: none;
}
.appbar-right .MuiTabs-indicator{
  background-color: #fff;
}
.appbar-right .MuiPaper-root{
    background: transparent;
    box-shadow: none;
    float: right;
}
.MuiDialogTitle-root.warning{
  background-color: #e74c3c;
  color: #fff;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink{
  background-color: #fff;
}
button.delete-btn,
button.delete-btn:hover{
  float: right;
  background-color: red;
  color: #fff;
  margin-left: 8px;
}
button.add-btn,
button.add-btn:hover
{
    float: right;
    /* background-color: green;
    color: #fff;
    margin-left: 8px;
    margin-bottom: 10px; */
}
button.update-btn,
button.update-btn:hover
{
    float: right;
    background-color: #2980b9;
    color: #fff;
    margin-left: 8px;
    margin-bottom: 10px;
}
button.add-btn.service{
  background-color: #2980b9;
}
.MuiDialogActions-root button.add-btn,
.MuiDialogActions-root button.delete-btn,
.MuiDialogActions-root button.update-btn
{
  margin-bottom: 0px;
}

.card-service .MuiCardHeader-action{
  margin-right: 10px;
}

.react-tel-input{
  margin-top: 16px;
  margin-bottom: 8px;
}
div.loading{
  display: flex;
  justify-content: center;
  align-content: center;
  margin: auto;
}
div.loading img{
  max-width: 200px;
}
div.loading img.full{
  display: table;
  margin: auto;
}
.MuiContainer-root{
  margin-top: 80px;
  background-color: #fff;
  margin-bottom: 10px;
}
nav .brand-logo {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
nav a.sidenav-trigger{
  display: block!important;
}
ul.sidenav li{
  position: relative;
}
ul.sidenav li img{
  position: absolute;
  left: 10px;
  top: 15px;
  width: 20px;
  height: 20px;
}
ul.sidenav img.menu-logo{
  border-bottom: 1px solid #c8c8c8;
  width: 100%;
}
ul.sidenav li a{
  padding-left: 40px;
}
img.nav-logo{
  max-width: 80%;
  margin-left: 10%;
}
/* .paper {
  margin-top: 8px;
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';
} */
.avatar {
  margin: 1px,
}
.submit {
  margin: 3px 0px 2px,
}
.spinner{
  margin-left: 10px;
}
.userTable tbody tr td .MuiAvatar-root{
  margin: auto;
}
.regular-title{
  margin-top: 5px;
  color: #2b78e4;
}
.report-title{
  margin-top: 5px;
  color: #333;
}
h4.regular-title{
  margin: 0px;
}
.grid-user-group{
  border-left: 1px solid #c8c8c8;
}
.react-tel-input{
  margin-top: 8px;
}
.react-tel-input .form-control{
  padding-top: 10.5px!important;
  padding-bottom: 10.5px!important;
}
.section-user-bottom-container{
  margin-top: 20px!important;
  padding-top: 20px;
  border-top: 1px solid #c8c8c8;
}
.tableSection .MuiTableCell-head{
  z-index: 0;
}

.user-group-flow-container{
  max-height: 150px;
  overflow-y: scroll;
  padding-left: 5px;
}

.group-button-container{
  width: 100px;
}

.group-button-container button{
  margin-top: 2px;
}

ul.no-padding-list>li{
  padding-left: 0px;
  padding-right: 0px;
}

.MuiGrid-spacing-xs-1 > .MuiGrid-item  .MuiFormControl-marginDense,
.MuiGrid-spacing-xs-1 > .MuiGrid-item  .react-tel-input,
.MuiGrid-spacing-xs-2 > .MuiGrid-item  .MuiFormControl-marginDense,
.MuiGrid-spacing-xs-2 > .MuiGrid-item  .react-tel-input
{
  margin: 0px;
}

.tableSection .MuiTableCell-head{
  font-size: 12px;
}

.branch-logo{
  max-height: 140px;
  border: 1px solid #c8c8c8;
  max-width: calc(100% - 50px);
}

.logo-container,
.avatar-container{
  width: 100%;
  position: relative;
  cursor: pointer;
}
.avatar-container .react-aspect-ratio-placeholder{
  border: 1px solid #c8c8c8;
  margin: 0px;
  border-radius: 100%;
}
.logo-container .upload-picture{
  position: absolute;
  top: 0px;
}
.logo-container .delete-picture{
  position: absolute;
  bottom: 0px;
}

.count-string .MuiInputAdornment-positionEnd{
  font-size: 10px;
  position: absolute;
  right: 7px;
  top: -14px;
  background: #fff;
  z-index: 999;
  display: table!important;
  padding: 5px;
}
.total-user {
  font-size: 17px;
  line-height: 40px;
  font-weight: bold;
  color: green;
  margin-right: 10px;
}
#dialog-take-picture .MuiDialog-paper{
  width: calc(100% - 20px);
  margin: 10px;
}

.inline-section{
  text-align: center;
  position: relative;
}
.inline-section .MuiIconButton-root {
  position: absolute;
  right: 5px;
  top: 0px;
  padding: 5px;
}
.avatar-logo{
  border-radius: 100%;;
}

div .divider-line{
  width: 100%;
  height: 1px;
  background: #c8c8c8;
  margin: 10px;
}

button.square-button,
a.square-button{
  min-width: 180px;
  margin: 10px 20px;
  padding: 20px;
  background-color: #F5F6F5;
  border-color: #BDBDBF;
}
button.square-button>span,
a.square-button>span{
  flex-direction: column;
  text-align: center;
  font-size: 15px;
  text-transform: initial;
}
button.square-button > span > .MuiButton-startIcon,
a.square-button > span > .MuiButton-startIcon{
  margin: 0px;
  margin-bottom: 5px;
}
button.square-button > span > .MuiButton-startIcon > .MuiSvgIcon-root,
a.square-button > span > .MuiButton-startIcon > .MuiSvgIcon-root{
  font-size: 40px;
  height: 40px;
}
button.square-button > span > .MuiButton-startIcon img,
a.square-button > span > .MuiButton-startIcon img{
  height: 40px;
  width: 40px;
}

.report.report-time-tracking .MuiButton-label{
  text-transform: none;
}

.date-picker .MuiOutlinedInput-adornedEnd{
  padding-right: 0px;
}

.visually-hidden {
  border: 0px;
  clip: 'rect(0 0 0 0)';
  height: 1px;
  margin: -1px;
  overflow: 'hidden';
  padding: 0px;
  position: 'absolute';
  top: 20px;
  width: 1px;
}
.report-table{
  margin-top: 10px;
}

.report-table .MuiTableCell-root{
  padding: 5px 10px!important;
  border-left: 1px solid #e0e0e0;
  font-size: 13px;
}

.report-table .MuiTableCell-head{
  font-size: 15px;
}

.report-table .MuiTableCell-root:first-child{
  border-left-width: 0px;
}

.total-row .MuiTableCell-root{
  padding: 10px 5px!important;
  border-left-width: 0px;
  font-weight: bold;
  font-size: 15px;
  height: 43px;
}

.total-row .MuiTableCell-root:nth-last-child(2){
  color: #1a76d2;
}

.total-row .MuiTableCell-root:last-child{
  color: red;
}

.table-photo{
  max-height: 100px;
  max-width: 100px;
}

.report-data-cell {
  vertical-align: top!important;
}

p.table-location{
  min-height: 40px;
}

.report-checkbox > label > span:first-child{
  padding: 0px;
  margin-right: 5px;
  margin-left: 5px;
}

.MuiCheckbox-colorSecondary.Mui-checked{
  color: #1a76d2
}

.MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover{
  background-color: rgba(0, 0, 0, 0.04)!important;
}

.time-block{
  cursor: no-drop!important;
}

.time-block.deleteable:hover{
  border-color: red!important;
  cursor: pointer!important;
  color: red!important;
}
.rc-time-picker{
  margin-top: 8px;
  margin-bottom: 4px;
}

.rc-time-picker-panel{
  z-index: 99999!important;
}

.rc-time-picker-input{
  height: 40px!important;
  font-size: 16px!important;
  color: #000!important;
}
.rc-time-picker .MuiSvgIcon-root{
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 8px;
}
.time-picker-custom-input-label{
  transform: translate(14px, 2px) scale(0.75)!important;
  background: #fff!important;
  z-index: 999;
}

.rc-time-picker-clear{
  display: none!important;
}

li.rc-time-picker-panel-select-option-selected{
  background: #1a76d2!important;
  color: #fff!important;
}

.rc-time-picker-panel-select ul{
  padding-bottom: 120px!important;
}

.error .rc-time-picker-input{
  border-color: red!important;
}

.MuiTableCell-root .rc-time-picker-input{
  font-size: 13px!important;
  text-align: center;
}

.report-data-cell{
  max-width: 130px;
}
.report-data-cell.edit {
  position: relative;
}

.report-data-cell.edit button {
  position: absolute;
  right: 0px;
  top: 0px;
  opacity: 0;
}

.report-data-cell.edit:hover button {
  opacity: 1;
}

.report-data-cell.edit button .MuiSvgIcon-fontSizeSmall{
  font-size: 14px;
}
.time-picker-scroll.edit-table-cell .rc-time-picker-input{
  height: 28px!important;
}
.time-picker-scroll.edit-table-cell.edited .rc-time-picker-input{
  border-color: #1a76d2;
}

.container-csv-upload.one-file >div>div{
  width: 100%!important;
}

img.side-menu-icon{
  width: 23px;
  height: 23px;
}

.template-list.MuiGrid-spacing-xs-1{
  margin: 0px;
}

.template-item-description fieldset,
.template-item-price fieldset{
  border-color: #eeeeee!important
}

.template-item-quantity input,
.template-item-price input{
  text-align:  right;
  padding-left: 5px;
  padding-right: 5px;
}

.chart-container {
  min-height: 350px;
}

.title-cover h6{
  font-size: 18px;
}
.title-cover .subtitle{
  font-size: 10px;
  color: #e0e0e0;
}

.filter-button.active {
  background-color: #eaeaea;
}

.vote-button.active {
  background-color: #f5f5f5;
}
.vote-button svg{
  color: #ccc
}

.vote-button.up.active svg {
  color: green
}

.vote-button.down.active svg {
  color: red
}

/* 
.paper {
  background: #fff;
  border: 1px solid #c8c8c8;
  border-radius: 10px;
  padding: 20px;
} */

img.login-logo{
  max-height: 80px;
  margin: auto;
  display: table;
  margin-bottom: 10px;
}

body {
  background-image: url(./asset/img/laundry.png);
  background-repeat: no-repeat;
  background-size: 350px;
  background-position: left 50px center;
}


.remove-screenshot {
  position: absolute!important;
  right: -10px;
  top: -10px;
  background-color: rgb(255, 255, 255)!important;
  border-width: 1px;
  border-radius: 100px!important;
  border-color: #333;
  border: 1px solid #c8c8c8!important;
  padding: 3px!important;
  z-index: 999;
}

button.MuiButton-root{
  text-transform: none;
}
.percent-status {
  border-radius: 200px;
  text-align: center;
  padding: 0px 3px;
  font-size: 13px;
}
.percent-status.red {
  background-color: red;
  color: #fff;
}
.percent-status.green {
  background-color: green;
  color: #fff;
}
.percent-status.orange {
  background-color: orangered;
  color: #fff;
}
.percent-status.white {
  background-color: white;
  color: #333,
}

.font13 span{
  font-size: 13px;
}

.right-button-with-title{
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  display: table;
  float: right;
  text-align: center;
  cursor: pointer;
}

.MuiPickersStaticWrapper-staticWrapperRoot{
  border: 1px solid #c8c8c8;
}

.right-button-with-title div.header-label{
  font-size: 12px;
  margin-top: 0px;
  color: #ccc
}

.detail-quantity{
  position: relative;
  margin-right: 20px;
  min-height: 35px;
  line-height: 35px;
  font-size: 14px;
}

.detail-quantity-diff{
  position: absolute;
  top: -12px;
  right: -10px;
  font-size: 10px;
}

.detail-quantity-diff.red{
  color: red
}

.detail-quantity-diff.green{
  color: green
}

.MuiTableRow-root.active > .MuiTableCell-root:first-child{
  color: #1a76d2;
  font-weight: bold;
}
.MuiContainer-maxWidthSm.bottom-nav{
  position: relative;
  padding-bottom: 70px;
}
.MuiBottomNavigation-root{
  bottom: 0px;
  position: absolute;
  width: 100%;
  margin-left: -24px;
  background: transparent!important;
  border-top: 1px solid #c8c8c8;
}

.date-switch{
  justify-content: center;
  flex-direction: row;
  margin-bottom: 10px;
  display: flex;
  border: 1px solid #888;
  border-radius: 5px;
}
.date-switch button{
  padding: 5px;
  width: 50px;
}
.roster-date{
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 50px;
}
.tab-in-active{
  display: none;
}

@media only screen and (min-width: 1440px){
  .padding-grid.left{
    padding-left: 7%!important;
  }
  .padding-grid.right{
    padding-right: 7%!important;
  }
}

.MuiTableRow-root.editting-contact{
  background-color: #f3f5f5;
}

@media only screen and (min-width: 1200px) and (max-width: 1439px){
  .padding-grid.left{
    padding-left: 5%!important;
  }
  .padding-grid.right{
    padding-right: 5%!important;
  }
}

@media only screen and (max-width: 768px){
  .padding-grid.left{
    border-width: 0px;
  }
}

@media (min-width: 600px){
  .MuiContainer-root {
    /* padding-top: 10px;
    padding-bottom: 50px;
    padding-left: 24px;
    padding-right: 24px; */
    padding: 24px;
    border: 1px solid #c8c8c8;
    border-radius: 10px;
    min-height: 80vh;
    background-color: #fff;
  }
}

@media only screen and (max-width: 500px){
  button.square-button,
  a.square-button{
    min-width: 50px;
    width: 120px;
    margin: 5px;
    padding: 10px;
  }
  button.square-button>span,
  a.square-button>span{
    flex-direction: column;
    text-align: center;
    font-size: 13px!important;
    text-transform: initial;
  }
  button.square-button > span > .MuiButton-startIcon img,
  a.square-button > span > .MuiButton-startIcon img{
    height: 30px;
    width: 30px;
  }
  body {
    background-image: none;
  }
  .quantity-input input {
    padding: 10.5px 5px ;
    text-align: center;
  }
  .right-button-with-title svg.MuiSvgIcon-root{
    font-size: 15px;
  }
  .right-button-with-title div.header-label{
    font-size: 10px;
  }
  .MuiBottomNavigation-root{
    position: fixed!important;
  }
}

@media only screen and (max-width: 320px){
  .title-cover h6 {
    font-size: 14px;
  }
  .right-button-with-title div.header-label{
    font-size: 8px;
  }
}